/* Styles for the Banner section */
.Banner {
    display: flex; /* Arrange logos side-by-side (changed to column) */
    flex-direction: column; /* Stack elements vertically */
    justify-content: space-between; /* Distribute logos evenly */
    align-items: center; /* Align logos horizontally (may need adjustment) */
    padding: 20px; /* Add padding for spacing */
  }
  
  /* .Banner img {
    width: 600px; 
    height: auto;
  } */

  .avatarslogo {
    /* width: 900px; */
    width: 600px;
    height: auto; 
  }

  .gameroomlogo {
    /* width: 700px;  */
    width: 500px; 
    height: auto; 
  }
  
  /* Styles for each game section */
  .ZombiesFromOuterSpace,
  .HoverSk8,
  .threeDBowlingGame,
  .twoDGame {
    display: flex; /* Arrange elements in a column (already set) */
    flex-direction: column; /* Keeps elements stacked vertically */
    justify-content: center; /* Center elements vertically within the column */
    align-items: center; /* Center elements horizontally within the column */
    margin-bottom: 30px; /* Add spacing between sections */
    padding: 20px; /* Add padding for spacing */
    /* border: 1px solid #ddd;  */
    border-radius: 5px; /* Add rounded corners */
    /* color: white; */
    color: #6a6a6a;
  }
  
  /* Styles for the animation logo */
  .animationlogo {
    width: 500px; /* Increase width of the animation (adjust as needed) */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Add spacing below the image */
    border-radius: 15px;
  }
  
  /* Styles for text elements within each section */
  .ZombiesFromOuterSpace div,
  .HoverSk8 div,
  .threeDBowlingGame div,
  .twoDGame div {
    text-align: center; /* Center text horizontally (already set) */
    margin-bottom: 10px; /* Add spacing between text elements */
    /* color: white; */
    color: #6a6a6a;
  }
  
  /* Styles for buttons */
  .greenbutton,
  .bluebutton,
  .redbutton {
    width: 80px; /* Reduce width of buttons (adjust as needed) */
    height: 80px; /* Reduce height of buttons (adjust as needed) */
    margin-right: 10px; /* Add spacing between buttons */
    cursor: pointer; /* Indicate clickable elements (already set) */
  }

  .buttoncontainer {
    display: flex; /* Already set for horizontal layout */
  }
  
  .button-wrapper {
    display: flex; /* Enable flexbox for text and button within the wrapper */
    flex-direction: column; /* Stack text and button vertically */
    align-items: center; /* Center text and button horizontally within the wrapper */
    margin-right: 10px; /* Add spacing between button groups */
  }
  
  .buttoncontainer div:first-child { /* Remove if using margin-bottom on .button-wrapper */
    margin-bottom: 10px; /* Optional: Add spacing between text description and buttons (if needed) */
  }
  
  .jackbutton,
  .ripperbutton {
    width: 80px; /* Reduce width of buttons (adjust as needed) */
    height: 80px; /* Reduce height of buttons (adjust as needed) */
    /* Other button styles remain the same */
  }
  
  
  /* Styles for game screen images (optional) */
  .sk8screen,
  .bowlingscreen,
  .twodgamescreen {
    /* width: 100%; */
    width: 500px;
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 10px; /* Add spacing below image */
    border-radius: 15px;
  }
  
  /* Media Queries for Mobile Responsiveness (optional) */
  
  @media (max-width: 768px) {

    /* Adjust styles for smaller screens as needed */

    /* .Banner img {
      width: 100%; 
    } */

    .avatarslogo {
      /* width: 600px; */
      width: 300px;
      height: auto; 
    }

    .gameroomlogo {
      width: 300px; 
    }
  
    .ZombiesFromOuterSpace,
    .HoverSk8,
    .threeDBowlingGame,
    .twoDGame {
      flex-direction: column; /* Stack elements on top of each other */
      align-items: center; /* Center elements horizontally */
    }
  
    .sk8screen,
    .bowlingscreen,
    .twodgamescreen,
    .avatarslogo {
      width: 300px;
      width: auto; /* Allow images to scale proportionally */
    }
  }
  
  
  