/* Music.css */

.music-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 20px;
    align-items: flex-start;
    /* justify-content: center;  */
    justify-content: space-evenly; 
    gap: 10px; /* Introduce space between albums */
  }
  
  .album {
    width: 30%; /* Set width for each album */
    margin: 0; /* Remove margin for tighter spacing */
    text-align: center; /* Center text within album container */
  }
  
  .album-info {
    margin-bottom: 5px; /* Reduce margin for closer spacing */
  }
  
  .album img {
    width: 100%; /* Display images at full width */
    /* Additional styles for image (optional):
    border-radius: 5px; /* Add rounded corners */
  }
  
  .buttons {
    display: flex;
    justify-content: space-evenly; /* Distribute buttons evenly (reduces gap) */
  }
  
  .buttons button {
    padding: 10px 15px; /* Adjust padding if needed */
    border: none;
    border-radius: 3px;
    cursor: pointer;
    width: 120px; /* Adjust width as needed for your button text */
    /* Set background and text color */
    background-color: #333; /* Change this to your desired grey color */
    color: white;
    /* Add hover effect for color change (optional) */
    /* You can keep the hover effect or remove these lines if not needed */
    transition: background-color 0.2s ease-in-out; /* Smooth transition */
  }
    
  .buttons button:hover {
    background-color: #4f4e4e; /* Change this to your desired hover color */
  }
  
  /* Media Queries for Mobile Responsiveness */
  
  /* Media Queries for Mobile Responsiveness */

@media (max-width: 768px) {
    /* Styles for screens less than or equal to 768px wide */
    .music-container {
      justify-content: center; /* Center albums horizontally on smaller screens */
    }
  
    .album {
      width: 45%; /* Adjust album width for smaller screens */
    }
  
    .buttons {
      /* Buttons stay horizontal, adjust size with flex */
      flex: 1; /* Allow buttons to shrink/grow to fit container */
    }
  
    .buttons button {
      /* Reduce padding and font size on smaller screens */
      padding: 5px 8px; /* Adjust padding as needed */
      font-size: 0.8rem; /* Adjust font size as needed */
      /* Reduce button width here */
      width: 70px; /* Adjust width as needed for your button text length */
    }
  
    /* Apply smaller font size to all text within the .buttons element */
    .buttons {
      font-size: 0.8rem; /* Adjust font size as needed */
      /* overflow: hidden; 
      text-overflow: ellipsis; */
    }
  }
  
  /* Additional Media Query for Even Smaller Screens (optional) */
  
  @media (max-width: 480px) {
    /* Styles for screens less than or equal to 480px wide (e.g., smartphones) */
    .music-container {
      flex-direction: column; /* Stack albums on very small screens */
      align-items: center; /* Center albums vertically */
    }
  
    .album {
      width: 100%; /* Make albums full width on very small screens */
    }
  
    .buttons {
      /* Buttons stay horizontal, but reduce padding further */
      padding: 3px 5px; /* Adjust padding as needed */
      /* overflow: hidden; 
      text-overflow: ellipsis; */
    }
  }
  
  
  
  
  