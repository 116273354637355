/* Photos.css */

/* .photo-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  margin-bottom: 20px;
  width: 80%; 
}

.main-image img {
  width: 100%; 
  max-height: 400px; 
  object-fit: contain; 
}

.thumbnail-carousel {
     width: 45%;
}

.thumbnail-carousel img {
  width: 100%;
  height: auto;
  cursor: pointer;
}


.thumbnail-carousel .slick-slide {
  padding: 0 5px; 
}


.slick-prev,
.slick-next {
  width: 40px; 
  height: 40px; 
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 50%; 
  z-index: 1000; 
  top: 70%; 
  transform: translateY(-50%); 
  position: absolute;
}

.slick-prev:before,
.slick-next:before {
  content: ''; 
  display: inline-block;
  border: solid white; 
  border-width: 0 4px 4px 0; 
  padding: 5px; 
}

.slick-prev {
  left: 1px; 
}

.slick-next {
  right: 1px;
}

.slick-prev:before {
  transform: rotate(135deg); 
}

.slick-next:before {
  transform: rotate(-45deg); 
}

@media (max-width: 768px) {
  .thumbnail-carousel {
    width: 100%;
    overflow: hidden; 
  }

  .thumbnail-carousel img {
    width: 90px; 
  }


  .thumbnail-carousel .slick-slide {
    flex: 0 0 auto; 
  }

  .main-image img {
    width: 100%; 
    max-height: 400px; 
    object-fit: contain; 
    height: 300px; 
  }

  
  .thumbnail-carousel {
    width: 60%;
  }

  .slick-prev {
    left: -10px; 
  }
  
  .slick-next {
    right: -10px;
  }


  .slick-prev,
.slick-next {
  
  top: 50%;
}
} */







.photo-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.main-image {
  margin-bottom: 20px;
  width: 80%;
}

.main-image img {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}

.thumbnail-carousel {
  width: 45%;
}

.thumbnail-carousel img {
  width: 100%;
  height: auto;
  cursor: pointer;
}

.slick-slide {
  padding: 0 5px;
}

.slick-prev,
.slick-next {
  width: 40px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  z-index: 1000;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* Rest of arrow styles remain the same */

@media (max-width: 768px) {
  .main-image {
    width: 90%;
    margin-bottom: 10px;
  }

  .main-image img {
    height: 300px;
    object-fit: contain;
  }

  .thumbnail-carousel {
    width: 80%;
    display: block;
    position: relative;
    margin: 0 auto;
  }

  .thumbnail-carousel .slick-track {
    display: flex;
    align-items: center;
  }

  .thumbnail-carousel .slick-slide {
    padding: 0 2px;
    height: auto;
  }

  .thumbnail-carousel img {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .slick-prev {
    left: -20px;
  }

  .slick-next {
    right: -20px;
  }

  .slick-prev,
  .slick-next {
    width: 30px;
    height: 30px;
    top: 50%;
  }
}













/* Photos.css Test to place arrows outside the carousel */

/* .photo-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.main-image {
  margin-bottom: 20px;
  width: 80%; 
}

.main-image img {
  width: 100%; 
  max-height: 400px; 
  object-fit: contain; 
}

.thumbnail-carousel {


  position: relative; 
  width: 100%; 
}

.thumbnail-carousel img {
  width: 100%;
  height: auto;
  cursor: pointer;
}


.thumbnail-carousel .slick-slide {
  padding: 0 5px; 
}


.slick-prev,
.slick-next {
  width: 40px; 
  height: 40px; 
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 50%; 
  z-index: 1000; 

  top: 50%; 

  transform: translateY(-50%); 

}

.slick-prev:before,
.slick-next:before {
  content: ''; 
  display: inline-block;
  border: solid white; 
  border-width: 0 4px 4px 0; 
  padding: 5px; 
}

.slick-prev {
  left: 10px; 
}

.slick-next {
  right: 10px;
}

.slick-prev:before {
  transform: rotate(135deg); 
}

.slick-next:before {
  transform: rotate(-45deg); 
}

@media (max-width: 768px) {
  .thumbnail-carousel {
    width: 100%;
    overflow: hidden; 
  }

  .thumbnail-carousel img {
    width: 90px; 
  }



  .thumbnail-carousel .slick-slide {
    flex: 0 0 auto; 
  }

  .main-image img {
    width: 100%; 
    max-height: 400px; 
    object-fit: contain; 
  }
  
  .thumbnail-carousel {
    width: 60%;
  }

  .slick-prev {
    left: -10px; 
  }
  
  .slick-next {
    right: -10px;
  }


  .slick-prev,
.slick-next {
  
  top: 50%;
}
} */