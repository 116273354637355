/* .order-text {
    margin: 50px;
}


.tshirt-rows {
    margin-bottom: 80px;
} */

  


  .order-text {
    margin: 50px 0;
    text-align: center;
    /* color: white; */
    color: #6a6a6a;

  }
  
  .tshirt-rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 80px;
  }
  
  .tshirt-row {
    margin: 10px;
    text-align: center;
    flex: 1 0 auto;
    /* color: white; */
    color: #6a6a6a;
  }
  
  /* Image Styles for Responsive Sizing */
  .tshirt-image {
    max-width: 100%; /* Set max-width to adapt to container size */
    height: auto; /* Maintain aspect ratio */
  }
  
  /* Media Queries for Additional Mobile Adjustments */
  @media (max-width: 768px) {
    .tshirt-row {
      width: 90%; /* Adjust width for smaller screens */
      margin: 15px auto;
    }
  }
  
  
  