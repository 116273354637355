/* App.css */


/* html, body {
  overflow-x: hidden; 
  width: 100%; 
}

body {
  position: relative; 
}

* {
  box-sizing: border-box; 
} */


/* Set background color and text color */
body {
  background-color: black;
  color: white;
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}

/* Styles for App component */
.App {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

/* Styles for header */
header {
  background-color: black;
  padding: 20px;
}

.logo {
  width: 600px; /* Adjust size as needed */
}

nav ul {
  list-style-type: none;
  padding: 0;
  /* border: 1px solid #ddd;  */
}

nav ul li {
  display: inline-block;
  margin-right: 20px;
}


nav ul li a {
  /* color: white; */
  color: #a1a0a0;
  /* color: #6a6a6a; */
  text-decoration: none;
}

nav ul li a:hover {
  color: #ccc;
  /* color: #a1a0a0; */
}

/* Styles for footer */
footer {
  background-color: black;
  /* color: white; */
  color: #6a6a6a;
  padding: 20px;
  margin-top: auto; /* Push footer to bottom */
}

/* Styles for video container */
/* .video-container {
  position: relative;
  width: 100%;
  max-width: 560px; 
  margin: 0 auto; 
} */

/* .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
} */

/* Additional styles for the new single text */
#home h2 {
  margin-top: 20px;
}

#home p {
  margin-bottom: 20px;
}


.submenu {
  position: absolute; /* Position the sub-menu below the Home link */
  top: 100%; /* Align the sub-menu at the bottom of the Home link */
  left: 0; /* Align the sub-menu to the left of the Home link */
  background-color: black; /* Set background color */
  color: white; /* Set text color */
  padding: 10px; /* Add padding */
  list-style-type: none; /* Remove default list style */
  display: none;
}


/* Media Queries for Mobile Responsiveness */



/* @media only screen and (max-width: 768px) {
  


  .App {

    margin-bottom: 0; 
    padding-bottom: 20px; 
  }

  header {
    flex-direction: column; 
    align-items: center; 
  }

  
  .logo {
    width: 400px;
    margin: 0 auto; 
  }


  nav {
    list-style-type: none;
    padding: 0;
    text-size-adjust: 30%;
  }

  
  nav ul {
    list-style-type: none;
    padding: 0;
  }

  nav ul li {
    display: inline-block;
    margin-right: 10px;
    color: #a1a0a0;

  }

  
  nav ul li a:hover {
    color: #ccc;

  }

  nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 14px; 
  }

 
  footer {
    background-color: black;
    padding: 10px;
    margin-top: auto;
    text-align: center; 
  }

} */



/* Media Queries for Mobile Responsiveness */
@media only screen and (max-width: 768px) {
  /* Center the App content */
  .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto; /* Ensure full centering */
    padding: 10px;
  }

  /* .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 100%; 
    overflow: hidden; 

    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%; 
  } */
  
  /* .video-container iframe,
  .video-container .react-player {
    width: 90%; 
    max-width: 600px; 
    height: auto; 
    border: none; 

    width: 90vw; 
    max-width: 600px; 
    height: auto; 
    border: none; 
  } */

  header {
    flex-direction: column; /* Stack logo and nav vertically */
    align-items: center;
    text-align: center; /* Center text inside the header */
  }

  /* Update logo size for better fit */
  .logo {
    /* width: 300px;  */
    width: 400px;
    margin-bottom: 10px;
  }

  /* Navbar improvements */
  nav ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center nav links */
    padding: 0;
    margin: 0;
  }

  nav ul li {
    display: inline-block;
    margin: 5px 10px; /* Add space between links */
  }

  nav ul li a {
    font-size: 16px; /* Increase font size for readability */
    /* color: white; */
    color: #a1a0a0;
    text-decoration: none;
    padding: 5px 10px; /* Add padding for better tap area */
  }

  nav ul li a:hover {
    color: #ccc;
  }

  /* Footer adjustments */
  footer {
    padding: 15px;
    font-size: 14px; /* Adjust font size */
    text-align: center;
  }
}


@media (max-width: 768px) { /* Adjust the breakpoint as needed */
  html, body {
    overflow-x: hidden; /* Disables horizontal scrolling */
    width: 100%; /* Ensures the body width stays within the viewport */
  }

  body {
    position: relative; /* Maintains normal scrolling for vertical content */
  }

  * {
    box-sizing: border-box; /* Avoids content overflow caused by padding or borders */
  }
}