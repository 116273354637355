/* Contact.css */

section#contact {
    padding: 20px;
    text-align: center; /* Center entire contact section */
  }
  
  section#contact h2 {
    margin-bottom: 15px;
    font-family: Arial, sans-serif;
    font-weight: bold;
    /* color: white; */
    color: #6a6a6a;
  }
  
  section#contact p {
    margin-top: 30px;
    margin-bottom: 30px; /* Increase space after paragraph */
    /* color: white; */
    color: #6a6a6a;
  }
  
  section#contact form {
    display: flex; /* Arrange elements horizontally */
    justify-content: center; /* Center form elements within the section */
  }
  
  section#contact form label {
    margin-right: 10px; /* Add space between label and input */
    font-weight: bold;
  }
  
  section#contact form input[type="email"] {
    width: 250px;
    height: 30px;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-style: italic; /* Add italic style for placeholder */
    color: #ccc; /* Set placeholder text color */

  }
  
  section#contact form input[type="email"]:focus {
    /* Change styles when the input is focused */
    font-style: normal; /* Remove italic style */
    color: black; /* Set normal text color */
  }
  
  section#contact form button {
    background-color: #333;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    margin-left: 10px; /* Move button next to input */
  }
  
  section#contact form button:hover {
    background-color: #4f4e4e;
  }
  
  section#contact .contact-links {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center links horizontally */
    margin-top: 100px; /* Add space above links */
    padding: 0; /* Remove default padding */
    list-style: none;
  }
  
  section#contact .contact-links a {
    margin: 10px;
    /* color: white; */
    color: #6a6a6a;
    text-decoration: none;
    font-size: 0.8em;
  }
  
  section#contact .contact-links a:hover {
    color: #ccc;
    /* color: #a1a0a0; */
  }
  
  
  
  
  
  
  