/* Home.css */

/* Styles for the home section */
#home {
    padding: 20px;
    text-align: center;
  }
  
  /* Styles for the video container */
  .video-container {
    position: relative;
    width: 100%;
    max-width: 560px; 
    margin: 0 auto; 
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  /* .video-container {
    position: relative;
    width: 100%;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  } */
  
  /* Styles for the new single text */
  #home h2 {
    margin-top: 20px;
    /* color: white; */
    color: #6a6a6a;
  }
  
  #home p {
    margin-bottom: 20px;
    /* color: white; */
    color: #6a6a6a;
  }



  @media only screen and (max-width: 768px) {
    /* Styles for screens smaller than 768px */
  
    #home {
      padding: 20px;
      text-align: center;
    }
  
    /* Reduce the size of the video for mobile screens */
    .video-container {
      margin: 0 auto; 
      width: 100%;
    }
  
    /* .video-container iframe,
    .video-container .react-player {
      width: 90vw; 
      max-width: 600px; 
      height: auto; 
      border: none; 
  } */
  
    #home h2 {
      margin-top: 20px;
      font-size: 18px; 
    }
  
    #home p {
      margin-bottom: 20px;
      font-size: 14px; 
    }
  }
  