/* Blog.css */

/* .blog-container {
    margin: 50px auto; 
    max-width: 800px; 
  }
  
  .blog-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .blog-container iframe {
    display: block; 
    border: 1px solid #ddd; 
  } */
  

 /* ONLY THING NOT GREEN TEXTED */

  /* @media (max-width: 768px) {
    .blog-container {
      margin: 20px auto;
    }
  } */





  .mobile-blog-view {
    padding: 20px;
    text-align: center;
  }
  
  .tumblr-link {
    display: inline-block;
    padding: 10px 20px;
    margin-top: 10px;
    background-color: #34526f;
    color: white;
    text-decoration: none;
    border-radius: 5px;
  }
  
  .tumblr-link:hover {
    background-color: #2a4257;
  }
  
  .blog-iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
  
  @media (max-width: 768px) {
    .blog-container {
      min-height: 200px;
    }
  }




  /* .blog-container {
    margin: 20px auto;
    max-width: 100%;
    width: 100%;  
  }
  
  .blog-iframe {
    width: 100%; 
    height: 400px;
    min-height: 300px;
  }
  
  @media (max-width: 768px) {
    .blog-container {
      margin: 10px auto; 
    }
  
    .blog-iframe {
      height: 300px; 
    }
  } */
  



  