/* Bio component styles */
.bio {
    text-align: center; /* Center all content within the bio container */
    max-width: 800px; /* Set a maximum width for the bio container */
    margin: 0 auto; /* Center the bio container horizontally */
    padding: 0 20px; /* Add padding to the sides */
    /* margin-bottom: 80px; */

    color: #a1a0a0;
  }
  
  .bio p {
    margin-bottom: 1.5em; /* Increase margin bottom for better separation between paragraphs */
    line-height: 1.6; /* Set line height for better readability */
    /* color: #696969; */

    color: #a1a0a0;
  }

  
  .language-toggle {
    display: flex;
    justify-content: center; /* Center buttons horizontally within the toggle */
    margin: 10px 0; /* Add spacing above and below the toggle */
    cursor: pointer;
  }
  

  .language-toggle button,
  .language-toggle a {  /* Target both buttons and anchor tag */
    width: fit-content;  /* Adjust width based on content */
    padding: 10px 15px;  /* Adjust padding as needed */
    margin-right: 5px;  /* Add spacing between buttons */
    text-align: center;  /* Center text within buttons */
    border: none;  /* Remove default button border */
    border-radius: 3px;  /* Maintain rounded corners */
    cursor: pointer;  /* Maintain hover cursor style */
    text-decoration: none; /* Remove underline from anchor tag */
  }
  
  
  .language-toggle button:hover,
  .language-toggle a:hover {  /* Apply hover styles to both buttons and anchor tag */
    background-color: #4f4e4e;
  }
  
  /* Press button styles */
  .pressbutton {
    background-color: #333;
    color: #ccc;
   }


  .language-toggle a {
    padding: 8px 12px; 
    /* text-align: center; */
    font-size: 0.89em;
  }
  
  
  .pressbutton:hover {
    background-color: #4f4e4e;
  }
  
  @media (max-width: 768px) {
    /* Styles for smaller screens */
    .bio {
      font-size: 0.8em; /* Adjust font size for mobile */
    }
  
    .pressbutton {
      padding: 8px 12px; /* Adjust padding for smaller buttons */
    }

    .language-toggle button,
    .language-toggle a {
      padding: 8px 12px; /* Adjust padding for smaller buttons */
      font-size: 1em;
    }
  }
  