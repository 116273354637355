

.videos-container {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    margin: 0 auto; 
    padding: 20px;
  }
  
  .video-item {
    margin-bottom: 20px; 
    text-align: center; 
  }
  
  .video-item iframe {
    display: block; 
    margin: 0 auto; 
  }


  .logoVR 
     {
        width: 500px;   
  }

  .grey-text {
    color: #a1a0a0;
  }
  
  
  @media (max-width: 768px) {

    .video-item {
      margin-bottom: 20px; 
      text-align: center; 
      width: 100%;
    }

    .video-item iframe {
      width: 100%; 
    }

    .logoVR 
    {
       width: 400px;   
 }
  }