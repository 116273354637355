/* Jatr2d css */

/* .jatr2d-container {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

.jatr2d-sections {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.jatr2d-section {
  text-align: center;
  width: 100%;
  margin-bottom: 20px;
}

.jatr2d-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.jatr2d-row {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.jatr2d-row p {
  margin: 0;
  padding: 0 7px;
}

.jatr2d-buttons div {
  display: inline-block;
  text-align: center;
}

.jatr2d-buttons img {
  margin: 0 5px;
  cursor: pointer;
}

.jatr2d-buttons span {
  display: block;
}

.jatr-pixels {
  width: 100%;
  height: auto;
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .jatr2d-sections {
    flex-wrap: wrap;
  }

  .jatr-pixels {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
} */















/* Jatr2d css */

.jatr2d-container {
    text-align: center;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .jatr2d-sections {
    display: flex;
    justify-content: space-between; 
    gap: 20px; 
  }
  
  .jatr2d-section {
    text-align: center;
    width: 100%; 
    margin-bottom: 20px; 
  }
  
  .jatr2d-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .jatr2d-row {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .jatr2d-row p {
    margin: 0; 
    padding: 0 7px; 

  }

  .jatr2d-row div {
    /* color: white; */
    color: #6a6a6a;
  }
  
  .jatr2d-buttons img {
    margin: 0 5px;
    cursor: pointer; 
  }
  
  .jatr-pixels {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }


  @media only screen and (max-width: 768px) {


   .jatr2d-sections {
    flex-wrap: wrap; 
  }

      
    .jatr-pixels {
      width: 100%;
      height: auto;
      margin-top: 40px;
    }
  
  }
  
  
  

















