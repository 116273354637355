/* .videos-container {
    display: flex; 
    flex-direction: column; 
    align-items: center; 
    margin: 0 auto; 
    padding: 20px;
  }
  
  .video-item {
    margin-bottom: 20px; 
    text-align: center; 
  } */

  .videos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .video-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .video-item iframe {
    display: block; 
    margin: 0 auto; 
  }
  
  /* Media Queries for Mobile Responsiveness */
  
  /* @media (max-width: 768px) {

    .video-item {
      margin-bottom: 20px; 
      text-align: center; 
      width: 100%;
    }

    .video-item iframe {
      width: 100%; 
    }
  } */
  






  /* .videos-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  
  .video-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */